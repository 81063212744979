import PlayIcon from '../../assets/playbutton.svg';
import InfoIcon from '../../assets/ibutton.svg';

type Props = { logo: string; desc: string; info: string; app_url: string; learn_more: string; video_link: string; };

function UserApp(props: Props) {

  const redirectApp: any = (app_url: string) => {
    window.open(
      app_url,
      '_blank'
    );
  }

  return (
    <section className="widgets-grid">                              
      <div className={"widget " + (props.video_link)} onClick={() => redirectApp(props.app_url)}>
            <div className="tile-content">
              <img src={props.logo} alt="App Logo" className="widget-logo" />
            </div>  
            <div className="bg-app-strip">
              <a href={props.learn_more} className="learn-more-cta" onClick={(e) => e.stopPropagation()}>Learn more</a>
            </div>
            <div className="has-tooltip"><img src={InfoIcon} className="i-icon h-4 w-4 cursor-pointer" />
              <span className="tooltip rounded shadow-lg p-1.5 bg-tooltip text-white text-xs -mt-12 cursor-pointer">{props.info}</span>
            </div>
          </div>    

    </section>



    // <div className="h-full w-full text-black-400 flex flex-col justify-between">
    //   <div className="h-full w-full flex flex-col justify-center place-items-center px-12 cursor-pointer" onClick={() => redirectApp(props.app_url)}>
    //     <img src={props.logo} alt="App Logo" className="w-full" />
    //     <p className="text-center text-xs font-raleway font-semibold mt-4">{props.desc}</p>
    //   </div>
    //   <div className="bg-app-strip flex flex-row justify-between items-center px-2 py-1 lg:py-2">
    //     <div className="flex flex-row justify-between items-center">
    //       {props.video_link != '#' && props.video_link.toLowerCase() != 'ignore' && (
    //         <a href={props.video_link} target="_blank" className="flex"><img src={PlayIcon} className="h-9 w-9" /></a>
    //       )}
    //       {props.learn_more != '#' && props.video_link.toLowerCase() != 'ignore' && (
    //         <p className="ml-1 my-1"><a href={props.learn_more} target="_blank" className="text-white text-lg font-raleway no-underline hover:underline">Learn more</a></p>
    //       )}
    //     </div>
    //     <div className="has-tooltip">
    //       <span className="tooltip rounded shadow-lg p-1.5 bg-tooltip text-white text-xs -mt-12 cursor-pointer">{props.info}</span>
    //       <img src={InfoIcon} className="h-4 w-4 cursor-pointer" />
    //     </div>
    //   </div>      
    // </div>
  );
}
export default UserApp;
