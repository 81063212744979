import { useOktaAuth } from '@okta/okta-react';

import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import PolygonBanner from 'components/PolygonBanner';
import OurServices from 'components/OurServices';

import NuviasDigital from 'assets/nuviasdigital.svg';

const HomePage = () => {
  const { authState, oktaAuth } = useOktaAuth();
  
  return (
    <div>
      {/* <PolygonBanner /> */}
      <Navbar />

      {/* <section className="hero bg">
        <div className="wrapper">
          
          <p>
            Welcome to Nuvias Digital, your on-demand digital channel toolkit. Here you will find instant access to a
            range of digital solutions to make the most of your commercial opportunities - from access to transparent
            order tracking and supply chain stocking status, to specialist marketing services and essential training to
            keep your knowledge at the sharp end of evolving tech trends.
          </p>
          <p>Just select what you need to boost your productivity.</p>
        </div>
      </section> */}

      {/* <header className="site-header" role="banner">
      <div className="right">
          <a className="btn website-btn" href="https://www.nuvias.com/">
            <svg className="globe-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 38 38"><defs><style>.icon{fill:currentColor;}</style></defs><path class="icon" d="M33,19A14,14,0,0,0,6.4,12.8h0a14,14,0,0,0,0,12.2h0A14.1,14.1,0,0,0,19,33h0A14,14,0,0,0,33,19Zm-26.8.6h4.4a17.1,17.1,0,0,0,.9,4.7H7.3A15.3,15.3,0,0,1,6.2,19.6Zm1.1-5.9h4.2a17.1,17.1,0,0,0-.9,4.7H6.2A15.3,15.3,0,0,1,7.3,13.7Zm24.5,4.7H27.4a17.1,17.1,0,0,0-.9-4.7h4.2A15.3,15.3,0,0,1,31.8,18.4Zm-5.6,0H19.6V13.7h5.6A17,17,0,0,1,26.2,18.4Zm-6.6-5.8V6.7a16.4,16.4,0,0,1,5.1,5.9Zm-1.2,0H13.3a16.4,16.4,0,0,1,5.1-5.9Zm0,1.1v4.7H11.8a17,17,0,0,1,1-4.7Zm-6.6,5.9h6.6v4.7H12.8A17,17,0,0,1,11.8,19.6Zm6.6,5.8v5.9a16.4,16.4,0,0,1-5.1-5.9Zm1.2,0h5.1a16.4,16.4,0,0,1-5.1,5.9Zm0-1.1V19.6h6.6a17,17,0,0,1-1,4.7Zm7.8-4.7h4.4a15.3,15.3,0,0,1-1.1,4.7H26.5A17.1,17.1,0,0,0,27.4,19.6Zm2.7-7H26a16.3,16.3,0,0,0-4.8-6.2A12.5,12.5,0,0,1,30.1,12.6ZM16.8,6.4A16.3,16.3,0,0,0,12,12.6H7.9A12.5,12.5,0,0,1,16.8,6.4Zm-8.9,19H12a17.3,17.3,0,0,0,4.8,6.2A12.5,12.5,0,0,1,7.9,25.4Zm13.3,6.2A17.3,17.3,0,0,0,26,25.4h4.1A12.5,12.5,0,0,1,21.2,31.6Z"></path></svg> www.nuvias.com</a>
        </div>
      </header> */}

      <main className="main">
        <div className="wrapper">
        <section className="main-content">
          <h2>Welcome to ONE by Infinigate, your on-demand digital channel toolkit</h2>
          {/* <p>Here you will find instant access to a range of digital solutions to make the most of your commercial opportunities. Keep your knowledge at the sharp end of evolving tech trends. Access various tools —
from transparent order tracking and supply chain stocking status, to specialist marketing services and essential training.</p> */}
          <h3>Just select what you need to boost your productivity</h3> 
          {/* <p>For more information and tutorials on how to use each of the services, follow the <i>'Learn more'</i> link.</p> */}
        </section>          
          <div className="subscription"></div>
          <div className="skillup"></div>
          <div className="solutionsportal"></div>
          <div className="powerup"></div>
          <div className="supplychain"></div>
          <div className="powerbuilder"></div>
          <div className="moreinfo"></div>          
          <OurServices />   

          <aside className="request-cta">
          <p><a className="btn red-button" href="https://www.nuvias.com/en-us/request-additional-features/">Request additional features</a></p>
          </aside>

        </div>
      </main>
      

      {/* <PolygonBanner /> */}
      <Footer />
    </div>
  );
};

export default HomePage;
