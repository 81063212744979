import React from 'react';
import axios, { AxiosError } from 'axios';
import { useOktaAuth } from '@okta/okta-react';

import UserApp from 'components/UserApp';
import useAsync from 'hooks/useAsync/index';

const NUVIAS_API_URL = process.env.REACT_APP_NUVIAS_API_URL;

const OurServices = () => {
  const { authState } = useOktaAuth();

  const accessToken = authState.accessToken?.accessToken!;

  const callUserAppsAPI = React.useCallback(() => {
    return axios
      .get(`${NUVIAS_API_URL}/NuviasUserApps`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(res => res.data);      
  }, [accessToken]);

  const {
    execute,
    status,
    value: userApp,
    error,
  } = useAsync<{ result: UserApps[] }, AxiosError>(callUserAppsAPI, true);

  return (    
    <div>      
      {status === 'loading' && (
        <div className="flex flex-row justify-center items-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-16 w-16 my-12"></div>
        </div>
      )}
      {status === 'success' && (
        <div className="mb-42px grid grid-flow-row grid-cols-1 sm:grid-cols-2 grid-900:grid-cols-3 gap-4 md:gap-6 grid-900:gap-4 lg:gap-10">
          {userApp?.result?.length != 0 &&
            userApp?.result?.sort((a, b) => parseInt(a.group.description) - parseInt(b.group.description)).map((item, index) => {
              return (
                <div key={index} className="aspect-w-3 aspect-h-2 min-w-15 desc:">
                  <UserApp
                    app_url={item.group.oktaAppURL}
                    logo={item.group.appTileImageURL}
                    desc={item.group.description}
                    video_link={item.group.playLink}
                    learn_more={item.group.learnMoreLink}
                    info={item.group.appInfo}
                  />
                </div>
              );
            })}
        </div>
      )}
      {status === 'success' && userApp?.result?.length == 0 && (
        <div className="flex flex-col justify-center items-center h-full bg-gray-100 py-12">
          <p className="text-heading-color text-xs lg:text-lg px-2 py-2">No apps assigned.</p>
        </div>
      )}
      {status === 'error' && (
        <div className="flex flex-col justify-center items-center h-full bg-gray-100 py-12">
          <p className="text-red-600 text-xs lg:text-lg px-2 py-2">
            {error?.response?.data.message || error?.toString()}
          </p>
          <button onClick={execute} className="bg-tooltip px-5 py-2 my-2 rounded text-white cursor-pointer border-none">
            Try Again
          </button>
        </div>
      )}
    </div>    
  );
};

export default OurServices;
