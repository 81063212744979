import NuviasDigital from './../../assets/nuviasdigital.svg';

const Navbar = () => {
  return (
    <header className="site-header" role="banner">
      
        {/* <div className="left">
          <img src={NuviasDigital} className="logo" alt="Nuvias Digital logo" />
        </div> */}
        <div className="left">
        <a className="btn website-btn" href="https://www.infinigate.com/">
            <svg className="globe-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 38 38"><defs></defs><path className="icon" d="M33,19A14,14,0,0,0,6.4,12.8h0a14,14,0,0,0,0,12.2h0A14.1,14.1,0,0,0,19,33h0A14,14,0,0,0,33,19Zm-26.8.6h4.4a17.1,17.1,0,0,0,.9,4.7H7.3A15.3,15.3,0,0,1,6.2,19.6Zm1.1-5.9h4.2a17.1,17.1,0,0,0-.9,4.7H6.2A15.3,15.3,0,0,1,7.3,13.7Zm24.5,4.7H27.4a17.1,17.1,0,0,0-.9-4.7h4.2A15.3,15.3,0,0,1,31.8,18.4Zm-5.6,0H19.6V13.7h5.6A17,17,0,0,1,26.2,18.4Zm-6.6-5.8V6.7a16.4,16.4,0,0,1,5.1,5.9Zm-1.2,0H13.3a16.4,16.4,0,0,1,5.1-5.9Zm0,1.1v4.7H11.8a17,17,0,0,1,1-4.7Zm-6.6,5.9h6.6v4.7H12.8A17,17,0,0,1,11.8,19.6Zm6.6,5.8v5.9a16.4,16.4,0,0,1-5.1-5.9Zm1.2,0h5.1a16.4,16.4,0,0,1-5.1,5.9Zm0-1.1V19.6h6.6a17,17,0,0,1-1,4.7Zm7.8-4.7h4.4a15.3,15.3,0,0,1-1.1,4.7H26.5A17.1,17.1,0,0,0,27.4,19.6Zm2.7-7H26a16.3,16.3,0,0,0-4.8-6.2A12.5,12.5,0,0,1,30.1,12.6ZM16.8,6.4A16.3,16.3,0,0,0,12,12.6H7.9A12.5,12.5,0,0,1,16.8,6.4Zm-8.9,19H12a17.3,17.3,0,0,0,4.8,6.2A12.5,12.5,0,0,1,7.9,25.4Zm13.3,6.2A17.3,17.3,0,0,0,26,25.4h4.1A12.5,12.5,0,0,1,21.2,31.6Z"></path></svg> www.infinigate.com</a>
        </div>
        <div className="right">
<a className="btn website-btn" href="mailto:support.one@infinigate.com">
<svg className="globe-icon" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 38 38">
<path className="cls-1" d="M17,26a7.3,7.3,0,0,1-5.1-5.1l1.3-.4a6.3,6.3,0,0,0,4.2,4.2Zm4,0-.4-1.3a6.3,6.3,0,0,0,4.2-4.2l1.3.4A7.3,7.3,0,0,1,21,26Zm3.8-8.8a6,6,0,0,0-4.2-4.1l.4-1.4a7.5,7.5,0,0,1,5.1,5.1Zm-11.6,0-1.3-.4A7.5,7.5,0,0,1,17,11.7l.4,1.4A6,6,0,0,0,13.2,17.2Z"/>
<path className="cls-1" d="M17.2,31.5A12.8,12.8,0,0,1,6.4,20.7l1.4-.2a11.3,11.3,0,0,0,9.6,9.6Zm3.6,0-.2-1.4a11.3,11.3,0,0,0,9.6-9.6l1.4.2A12.8,12.8,0,0,1,20.8,31.5ZM7.8,17.2,6.4,17A12.7,12.7,0,0,1,17.2,6.3l.2,1.3A11.5,11.5,0,0,0,7.8,17.2Zm22.4,0a11.5,11.5,0,0,0-9.6-9.6l.2-1.3A12.7,12.7,0,0,1,31.6,17Z"/>
<path className="cls-1" d="M19.7,33H18.3a1.8,1.8,0,0,1-1.8-1.8V25.5a1.8,1.8,0,0,1,1.8-1.8h1.4a1.8,1.8,0,0,1,1.8,1.8v5.7A1.8,1.8,0,0,1,19.7,33Zm-1.4-7.9a.4.4,0,0,0-.4.4v5.7a.4.4,0,0,0,.4.4h1.4a.4.4,0,0,0,.4-.4V25.5a.4.4,0,0,0-.4-.4Z"/>
<path className="cls-1" d="M31.3,21.4H25.6a1.8,1.8,0,0,1-1.8-1.8V18.2a1.9,1.9,0,0,1,1.8-1.9h5.7a1.9,1.9,0,0,1,1.8,1.9v1.4A1.8,1.8,0,0,1,31.3,21.4Zm-5.7-3.7a.5.5,0,0,0-.4.5v1.4a.4.4,0,0,0,.4.4h5.7a.4.4,0,0,0,.4-.4V18.2c0-.3-.1-.5-.4-.5Z"/>
<path className="cls-1" d="M12.4,21.4H6.7a1.8,1.8,0,0,1-1.8-1.8V18.2a1.9,1.9,0,0,1,1.8-1.9h5.7a1.9,1.9,0,0,1,1.8,1.9v1.4A1.8,1.8,0,0,1,12.4,21.4ZM6.7,17.7c-.3,0-.4.2-.4.5v1.4a.4.4,0,0,0,.4.4h5.7a.4.4,0,0,0,.4-.4V18.2a.5.5,0,0,0-.4-.5Z"/>
<path className="cls-1" d="M19.7,14.1H18.3a1.9,1.9,0,0,1-1.8-1.9V6.6a1.8,1.8,0,0,1,1.8-1.9h1.4a1.8,1.8,0,0,1,1.8,1.9v5.6A1.9,1.9,0,0,1,19.7,14.1Zm-1.4-8a.5.5,0,0,0-.4.5v5.6a.5.5,0,0,0,.4.5h1.4a.5.5,0,0,0,.4-.5V6.6a.5.5,0,0,0-.4-.5Z"/>
<path className="cls-1" d="M6.2,12.8a.7.7,0,0,1-.7-.7V10.3a5,5,0,0,1,4.9-5h1.9a.7.7,0,0,1,0,1.4H10.4a3.6,3.6,0,0,0-3.5,3.6v1.8A.7.7,0,0,1,6.2,12.8Z"/>
<path className="cls-1" d="M31.8,12.8a.7.7,0,0,1-.7-.7V10.3a3.6,3.6,0,0,0-3.5-3.6H25.7a.7.7,0,1,1,0-1.4h1.9a5,5,0,0,1,4.9,5v1.8A.7.7,0,0,1,31.8,12.8Z"/>
<path className="cls-1" d="M12.3,32.4H10.4a4.9,4.9,0,0,1-4.9-5V25.6a.7.7,0,0,1,1.4,0v1.8A3.6,3.6,0,0,0,10.4,31h1.9a.7.7,0,0,1,0,1.4Z"/>
<path className="cls-1" d="M27.6,32.4H25.7a.7.7,0,0,1,0-1.4h1.9a3.6,3.6,0,0,0,3.5-3.6V25.6a.7.7,0,0,1,1.4,0v1.8A4.9,4.9,0,0,1,27.6,32.4Z"/>
</svg> Contact support</a>
</div>

        <div className="logo">          
          <svg className="logo-image" xmlns="http://www.w3.org/2000/svg" width="619.7" height="313.2" viewBox="0 0 619.7 313.2">
            <rect className="clr" x="79.3" width="48.6" height="131.86" rx="6.8"/>
            <path fill="#47baad" d="M318.2,20a89,89,0,0,0-88.8,88.9V230h48.4V108.9a40.4,40.4,0,1,1,80.8,0V230H407V108.9A88.9,88.9,0,0,0,318.2,20Z" transform="translate(-9.8 -12.6)"/>
            <path fill="#47baad" d="M629.5,126.4A104.7,104.7,0,1,0,590.4,208l-33.1-37a55.1,55.1,0,0,1-32.4,10.6c-30.5,0-40.3-18-49.3-36.1h151C628.7,137.1,629.5,135.4,629.5,126.4Zm-55.4-24.7H475.6a55.1,55.1,0,0,1,98.5-.1Z" transform="translate(-9.8 -12.6)"/>
            <path fill="#47baad" d="M158.6,35V97.7a54.6,54.6,0,1,1-91.2,1.2V35.4a103.6,103.6,0,1,0,91.2-.4Z" transform="translate(-9.8 -12.6)"/>
            <path fill="#565656" d="M250.6,310.6a15.1,15.1,0,0,1-7.6-2,14.5,14.5,0,0,1-5.3-5.4v6.7h-6.8V258.8h7.7v21.4a15.8,15.8,0,0,1,5.4-5.5,13.9,13.9,0,0,1,7.7-2.1,14.6,14.6,0,0,1,6.6,1.6,19.2,19.2,0,0,1,5.2,4.2,21.1,21.1,0,0,1,3.3,6.1,24.6,24.6,0,0,1,1.1,7.2,19.9,19.9,0,0,1-1.3,7.4,18.4,18.4,0,0,1-3.7,6,16.4,16.4,0,0,1-12.3,5.5Zm-2-6.6a10,10,0,0,0,4.6-1,12.2,12.2,0,0,0,3.6-2.7,12.8,12.8,0,0,0,2.4-3.9,14.5,14.5,0,0,0,.8-4.7,15,15,0,0,0-.8-4.7,14.1,14.1,0,0,0-2.2-4,11.7,11.7,0,0,0-3.5-2.7,9.3,9.3,0,0,0-4.4-1.1,10,10,0,0,0-6.3,2.3,16.5,16.5,0,0,0-4.2,5.6v9.7a7.8,7.8,0,0,0,1.4,2.9,13.7,13.7,0,0,0,2.5,2.3,14,14,0,0,0,3,1.5A11.8,11.8,0,0,0,248.6,304Z" transform="translate(-9.8 -12.6)"/>
            <path fill="#565656" d="M274.9,318.5l2,.4h1.7a2.4,2.4,0,0,0,1.5-.4,3.7,3.7,0,0,0,1.4-1.4,8.4,8.4,0,0,0,1.4-2.7c.5-1.2,1.1-2.7,1.8-4.6l-15.1-36.6h7.9l11.4,29.8L299,273.3h7.2l-16.6,45.1a11.6,11.6,0,0,1-3.8,5.3,11.8,11.8,0,0,1-7.2,2.1h-1.7a8.4,8.4,0,0,1-2-.5Z" transform="translate(-9.8 -12.6)"/>
            <path fill="#565656" d="M328.3,309.9V260.2h7.9v49.7Z" transform="translate(-9.8 -12.6)"/>
            <path fill="#565656" d="M379.2,309.9h-7.7V289.4c0-3.5-.5-6-1.6-7.6a5.7,5.7,0,0,0-4.9-2.3,10.6,10.6,0,0,0-3.4.6,10.9,10.9,0,0,0-3.1,1.8,13.3,13.3,0,0,0-2.6,2.7,11.6,11.6,0,0,0-1.8,3.5v21.8h-7.7V273.3h7v7.3a14.3,14.3,0,0,1,6.1-5.8,17.4,17.4,0,0,1,8.8-2.2,10.8,10.8,0,0,1,5.4,1.2,8.7,8.7,0,0,1,3.3,3.3,13.8,13.8,0,0,1,1.7,4.7,26.3,26.3,0,0,1,.5,5.7Z" transform="translate(-9.8 -12.6)"/>
            <path fill="#565656" d="M389.9,280.1H385v-5.9h4.9v-1.5a14.5,14.5,0,0,1,1-5.5,13.7,13.7,0,0,1,2.7-4.7,14.3,14.3,0,0,1,4.4-3.2,14.9,14.9,0,0,1,6.2-1.2,19,19,0,0,1,7,1.2,22.8,22.8,0,0,1,5.4,2.8l-3.1,5.8a12,12,0,0,0-4-2.3,11.5,11.5,0,0,0-4.8-1,7.6,7.6,0,0,0-3.2.7,6.5,6.5,0,0,0-2.2,1.8,9.6,9.6,0,0,0-1.2,2.5,9.2,9.2,0,0,0-.4,2.9v1.7h19.1v35.7h-7.7V280.1H397.7v29.8h-7.8Z" transform="translate(-9.8 -12.6)"/>
            <path fill="#565656" d="M458.9,309.9h-7.7V289.4c0-3.5-.5-6-1.6-7.6a5.7,5.7,0,0,0-4.9-2.3,9.6,9.6,0,0,0-3.3.6,10.1,10.1,0,0,0-3.2,1.8,16.6,16.6,0,0,0-2.6,2.7,11.6,11.6,0,0,0-1.8,3.5v21.8h-7.7V273.3h7v7.3a14.3,14.3,0,0,1,6.1-5.8,17.7,17.7,0,0,1,8.8-2.2,10.8,10.8,0,0,1,5.4,1.2,8.7,8.7,0,0,1,3.3,3.3,13.9,13.9,0,0,1,1.8,4.7,34.8,34.8,0,0,1,.4,5.7Z" transform="translate(-9.8 -12.6)"/>
            <path fill="#565656" d="M467.9,267.2v-8.4h7.7v8.4Zm0,42.7V273.3h7.7v36.6Z" transform="translate(-9.8 -12.6)"/>
            <path fill="#565656" d="M499.4,310.2a15.1,15.1,0,0,1-6.8-1.5,14.3,14.3,0,0,1-5.3-4.1,20.5,20.5,0,0,1-3.5-5.9,21.2,21.2,0,0,1-1.2-7.1,20.2,20.2,0,0,1,1.3-7.4,17.9,17.9,0,0,1,3.5-6,17.6,17.6,0,0,1,5.3-4.1,15.8,15.8,0,0,1,6.9-1.5,14.2,14.2,0,0,1,7.8,2.1,18,18,0,0,1,5.5,5.5v-6.9h6.8v35.5a15.8,15.8,0,0,1-1.5,7.1,14,14,0,0,1-4.1,5.2,17.5,17.5,0,0,1-6.2,3.3,27,27,0,0,1-7.7,1.1,21.6,21.6,0,0,1-10.1-2.1,18.7,18.7,0,0,1-6.8-5.7l4.4-4.1a13.1,13.1,0,0,0,5.4,4.5,17.1,17.1,0,0,0,7.1,1.5,16.3,16.3,0,0,0,4.5-.6,10.7,10.7,0,0,0,3.8-2,10.3,10.3,0,0,0,2.5-3.3,10.7,10.7,0,0,0,1-4.9v-5.6a12.9,12.9,0,0,1-5.4,5.2A15.4,15.4,0,0,1,499.4,310.2Zm2.5-6.2a9.2,9.2,0,0,0,3.3-.6,10.8,10.8,0,0,0,3-1.6,13.1,13.1,0,0,0,2.4-2.3,10.5,10.5,0,0,0,1.4-2.8v-9.6a15,15,0,0,0-1.9-3.2,14.2,14.2,0,0,0-2.5-2.4,13.4,13.4,0,0,0-3.1-1.7,8.7,8.7,0,0,0-3.2-.6,9.4,9.4,0,0,0-4.5,1.1,10.5,10.5,0,0,0-3.4,2.9,14.2,14.2,0,0,0-2.1,13.3,11.4,11.4,0,0,0,2.5,3.9,10.4,10.4,0,0,0,3.6,2.6A9.3,9.3,0,0,0,501.9,304Z" transform="translate(-9.8 -12.6)"/>
            <path fill="#565656" d="M538.7,310.6a13.5,13.5,0,0,1-5-.9,12.8,12.8,0,0,1-3.9-2.4,10.4,10.4,0,0,1-2.6-3.6,11.8,11.8,0,0,1-.9-4.6,9.3,9.3,0,0,1,1.1-4.7,9.9,9.9,0,0,1,3.2-3.6,12.5,12.5,0,0,1,4.9-2.4,20.6,20.6,0,0,1,6.2-.9,29.6,29.6,0,0,1,5.2.5,26.7,26.7,0,0,1,4.5,1.2v-2.5a8.5,8.5,0,0,0-2.3-6.3c-1.5-1.6-3.8-2.3-6.7-2.3a16.5,16.5,0,0,0-5.7,1.1,25.9,25.9,0,0,0-5.9,3.1l-2.4-4.9a26,26,0,0,1,14.7-4.8c5.1,0,9,1.3,11.8,3.9s4.2,6.2,4.2,11v13.3c0,1.6.7,2.5,2,2.5v6.6l-1.9.3h-1.6a4.5,4.5,0,0,1-3.4-1.1,4.8,4.8,0,0,1-1.5-2.9l-.2-2.3a17.3,17.3,0,0,1-6.1,5A18.4,18.4,0,0,1,538.7,310.6Zm2.1-5.7a13,13,0,0,0,5.2-1,8.9,8.9,0,0,0,3.9-2.8,3.8,3.8,0,0,0,1.5-2.7v-4.8a25.8,25.8,0,0,0-4.2-1.2,20.9,20.9,0,0,0-4.3-.4,12.3,12.3,0,0,0-6.7,1.7,5.5,5.5,0,0,0-2.7,4.8,6,6,0,0,0,2.1,4.6A7.5,7.5,0,0,0,540.8,304.9Z" transform="translate(-9.8 -12.6)"/>
            <path fill="#565656" d="M588.5,308a29.8,29.8,0,0,1-4,1.6,17.3,17.3,0,0,1-5.6.9,12.7,12.7,0,0,1-3.4-.5,10.6,10.6,0,0,1-2.8-1.5,6.5,6.5,0,0,1-2-2.6,10.3,10.3,0,0,1-.7-3.8V279.2h-4.8v-5.9H570V261.2h7.7v12.1h8v5.9h-8v20.5a3.6,3.6,0,0,0,1.3,2.8,4,4,0,0,0,2.7.9,7.7,7.7,0,0,0,3-.6l2.2-.9Z" transform="translate(-9.8 -12.6)"/>
            <path fill="#565656" d="M609.5,310.6a19.2,19.2,0,0,1-7.7-1.5,18.4,18.4,0,0,1-9.9-10.1,20.1,20.1,0,0,1-1.3-7.3,19.7,19.7,0,0,1,1.3-7.3,16.8,16.8,0,0,1,3.9-6.1,16.3,16.3,0,0,1,6-4.2,19.4,19.4,0,0,1,7.8-1.5,18.1,18.1,0,0,1,7.8,1.6,18.9,18.9,0,0,1,5.9,4.1,17.3,17.3,0,0,1,3.7,5.9,19.4,19.4,0,0,1,1.3,7.1,8.1,8.1,0,0,1-.1,1.6,4.1,4.1,0,0,1-.1,1.2H598.8a11.9,11.9,0,0,0,1.1,4.4,9.7,9.7,0,0,0,2.5,3.4,9.5,9.5,0,0,0,3.4,2.2,9.2,9.2,0,0,0,4,.8,10.6,10.6,0,0,0,3.1-.4,8.1,8.1,0,0,0,2.7-1.1,10.2,10.2,0,0,0,2.3-1.8,9.1,9.1,0,0,0,1.5-2.3l6.6,1.9a15.2,15.2,0,0,1-6.3,6.8A19.8,19.8,0,0,1,609.5,310.6Zm11.2-21.8a12.5,12.5,0,0,0-1.2-4.3,10,10,0,0,0-2.4-3.3,10.8,10.8,0,0,0-3.4-2.1,11.6,11.6,0,0,0-4.2-.8,11.5,11.5,0,0,0-4.1.8,10.3,10.3,0,0,0-3.3,2.1,10,10,0,0,0-2.4,3.3,15.2,15.2,0,0,0-1.2,4.3Z" transform="translate(-9.8 -12.6)"/>
          </svg>
        </div>
      
      <h1>Connecting Personally &amp; Digitally</h1>
    </header>
  );
};

export default Navbar;
