import LinkedInIcon from '../../assets/social-icon_linkedin.svg';
import TwitterIcon from '../../assets/social-icon_twittter.svg';
import YoutubeIcon from '../../assets/social-icon_youtube.svg';

const Footer = () => {
  return (
    <footer className="page-footer">
      <div className="wrapper">
        <div className="footer-cta">
          <h3>For additional info:</h3>
          <p>E: <a href="mailto:one@infinigate.com">one@infinigate.com</a> | W: <a href="https://www.infinigate.com" target="_blank">www.infinigate.com</a>
          </p>
        </div>

        <br></br>
        <div className="footer-legal">
            <div className="footer-social">
              <h3>Follow us:</h3>
             <p> 
              <a href="https://www.linkedin.com/company/infinigate" target="_blank">
                <img src={LinkedInIcon} alt="LinkedIn" width="36" height="36" />
              </a>
              <a href="https://twitter.com/InfinigateGroup" target="_blank">
                <img src={TwitterIcon} alt="Twitter" width="36" height="36" />
              </a>              
              <a href="https://www.youtube.com/@InfinigateGroup" target="_blank">
                <img src={YoutubeIcon} alt="YouTube" width="36" height="36" />
              </a>
            </p>             
            </div>
            <div className="footer-legal-content">
              <p><strong>Infinigate Holding AG</strong><br />
                Grundstrasse 14, CH-6343 Rotkreuz, Switzerland
              </p>              
              <p>© {new Date().getFullYear()} Infinigate | <a href="https://www.infinigate.com/privacy" target="_blank">Privacy Policy</a></p>
            </div>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
